<template>
  <el-dialog
    class="dialog-send-invoice"
    :visible="dialogSendInvoice"
    @close="handleSendInvoiceDialogClose"
    :close-on-click-modal="false"
    width= '50%'
    title="Send Invoice"
    style="text-align: left;"
  >
    <div>
      <el-form :model="sendInvoiceForm" ref="sendInvoiceForm">
        <h3 style="text-align: left; margin-top: 0;">Email:</h3>
        <p>(Multiple emails to be seperated by semicolon)</p>
        <el-form-item prop="invoiceSendEmails" :rules="[{ required: true, validator: this.sendEmailValidation, trigger: ['blur'] }]">
          <el-input
            :disabled="loading"
            v-model="sendInvoiceForm.invoiceSendEmails"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-dialog__footer">
      <el-button
        outline
        round

        @click="handleSendInvoiceDialogClose(false)"
      >
        Cancel
      </el-button>
      <el-button
        round
        type="primary"
        :loading="loading"
        @click="sendInvoice"
      >
        Send
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sendInvoice } from '@/services/modules/invoice.js'
export default {
  name: 'DialogInvoiceSend',
  props: {
    invoiceId: String,
    dialogSendInvoice: Boolean
  },
  data () {
    return {
      sendInvoiceForm: {
        invoiceSendEmails: ''
      },
      loading: false
    }
  },
  methods: {
    sendEmailValidation (rule, value, callback) {
      const regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      const emails = value.replace(' ', '').split(';')
      let isExtraSemiColon = false
      emails.forEach(email => {
        if (!regex.test(email) && email.trim() !== '') {
          callback(new Error('Please enter valid email addresses.'))
        } else if (email.trim() === '') {
          if (!isExtraSemiColon) {
            isExtraSemiColon = true
          } else {
            callback(new Error('Please enter valid email addresses.'))
          }
        }
      })
      callback()
    },
    sendInvoice () {
      const emails = this.sendInvoiceForm.invoiceSendEmails.replace(' ', '').split(';')
      if (emails[emails.length - 1].trim() === '') {
        emails.splice(emails.length - 1, 1)
      }
      const invoiceSendEmails = { emails: emails }
      this.loading = true
      sendInvoice(this.invoiceId, invoiceSendEmails).then(res => {
        this.loading = false
        if (res?.code === 1000) {
          this.$message.success('Invoice Email has been sent.')
          this.handleSendInvoiceDialogClose(true)
        }
      })
    },
    handleSendInvoiceDialogClose (isSent) {
      this.sendInvoiceForm.invoiceSendEmails = null
      this.$emit('handleSendInvoiceDialogClose', isSent)
      this.$refs.sendInvoiceForm.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
